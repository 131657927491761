<template>
	<div>
		<h3>{{ $t('table.general.sort.titre') }}</h3>

		<div class="col-12 col-md">
			<div class="form-group">
				<label>{{ $t('filtres.colonne') | capitalize }}</label>
				<e-select
					:options="columns"
					:searchable="true"
					allow-empty
					:show-labels="true"
					:search="true"
					:placeholder="$t('filtres.selectionnez_colonne')"
					v-model="internalSortBy"
				>
					<template slot="singleLabel" slot-scope="props">
						{{ getColumnTrad(table_type, props.option) }}
					</template>

					<template slot="option" slot-scope="props">
						{{ getColumnTrad(table_type, props.option) }}
					</template>
				</e-select>
			</div>
		</div>

		<div class="col-12 col-md">
			<div class="form-group">
				<label class="mr-2" for="sort-switch">
					{{ $t('table.general.sort.asc') }}
				</label>
				<b-form-checkbox
					v-model="internalSortDesc"
					switch
					id="sort-switch"
					class="d-inline"
				>
					{{ $t('table.general.sort.desc') }}
				</b-form-checkbox>
			</div>
		</div>
	</div>
</template>

<script>
import Table from '@/mixins/Table'

export default {
	name: 'TableConfigColumnSort',
	mixins: [Table],
	props: {
		table_type: String, // La table_cle
		columns: Array,     // Les colonnes sur lesquelles on peut appliquer un tri
		sort_by: String,    // La colonne sélectionnée pour le tri
		sort_desc: Boolean  // Tri ascendant ou descendant
	},
	computed: {
		internalSortBy: {
			get() {
				return this.sort_by
			},
			set(val) {
				this.$emit('update:sort_by', val)
			}
		},
		internalSortDesc: {
			get() {
				return this.sort_desc
			},
			set(val) {
				this.$emit('update:sort_desc', val)
			}
		}
	}
}
</script>
